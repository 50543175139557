import React from "react"
import Slider from "react-slick"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

import karthe from "../images/recommendation/claus-karthe.png"
import accelerator from "../images/recommendation/german-accelerator.png"
import kaiser from "../images/recommendation/wilma-kaiser.png"
import man from "../images/recommendation/man.png"
import groger from "../images/recommendation/nico-groger.png"
import cyberport from "../images/recommendation/cyberport.png"
import flughafen from "../images/recommendation/flughafen.png"
import havemeister from "../images/recommendation/havemeister.png"
import daimler from "../images/recommendation/daimler.png"
import fleischer from "../images/recommendation/fleischer.png"
import reischmann from "../images/recommendation/reischmann.png"
import reischmannLogo from "../images/recommendation/reischmann-logo.png"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
}

const Recommendation = () => {
  const { t } = useTranslation()

  return (
    <Slider {...settings}>
      <div>
        <div className="item">
          <div className="photo">
            <img src={kaiser} alt="Wilma Kaiser" />
          </div>
          <div className="content">
            <p>
              „<Trans>customers.opinion-1</Trans>“
            </p>
            <div className="data-wrapper">
              <div className="company-logo">
                <img src={man} alt="MAN" />
              </div>
              <div className="person-data">
                <div className="name">Wilma Kaiser</div>
                <div className="company">
                  <Trans>customers.opinion-1-position</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="item">
          <div className="photo">
            <img src={karthe} alt="Claus-Karthe" />
          </div>
          <div className="content">
            <p>
              „<Trans>customers.opinion-2</Trans>“
            </p>
            <div className="data-wrapper">
              <div className="company-logo">
                <img src={accelerator} alt="German Accelerator" />
              </div>
              <div className="person-data">
                <div className="name">Claus Karthe</div>
                <div className="company">
                  <Trans>customers.opinion-2-position</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="item">
          <div className="photo">
            <img src={groger} alt="Nico Gröger" />
          </div>
          <div className="content">
            <p>
              „<Trans>customers.opinion-3</Trans>“
            </p>
            <div className="data-wrapper">
              <div className="company-logo">
                <img src={cyberport} alt="Cyberport" />
              </div>
              <div className="person-data">
                <div className="name">Nico Gröger</div>
                <div className="company">
                  <Trans>customers.opinion-3-position</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="item">
          <div className="photo">
            <img src={havemeister} alt="Saskia Havemeister" />
          </div>
          <div className="content">
            <p>
              „<Trans>customers.opinion-4</Trans>“
            </p>
            <div className="data-wrapper">
              <div className="company-logo">
                <img src={flughafen} alt="Flughafen München" />
              </div>
              <div className="person-data">
                <div className="name">Saskia Havemeister</div>
                <div className="company">
                  <Trans>customers.opinion-4-position</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="item">
          <div className="photo">
            <img src={fleischer} alt="Sven Fleischer" />
          </div>
          <div className="content">
            <p>
              „<Trans>customers.opinion-5</Trans>“
            </p>
            <div className="data-wrapper">
              <div className="company-logo">
                <img src={daimler} alt="Daimler" />
              </div>
              <div className="person-data">
                <div className="name">Sven Fleischer</div>
                <div className="company">
                  <Trans>customers.opinion-5-position</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="item">
          <div className="photo">
            <img src={reischmann} alt="Andreas F. Reischmann" />
          </div>
          <div className="content">
            <p>
              „<Trans>customers.opinion-6</Trans>“
            </p>
            <div className="data-wrapper">
              <div className="company-logo">
                <img
                  src={reischmannLogo}
                  alt="Reischmann Apotheke Heidelberg"
                />
              </div>
              <div className="person-data">
                <div className="name">Andreas F. Reischmann</div>
                <div className="company">
                  <Trans>customers.opinion-6-position</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  )
}
export default Recommendation
