import React from "react"
import { Trans } from "gatsby-plugin-react-i18next"
import Slider from "react-slick"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faRoad,
  faUserShield,
  faDesktop,
  faCogs,
  faExpand,
  faChartBar,
  faShapes,
  faSlidersH,
  faPiggyBank,
  faLeafHeart,
  faTrafficLightGo,
} from "@fortawesome/pro-light-svg-icons"

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const BenefitsSlider = () => {
  return (
    <Slider {...settings}>
      <div className="item">
        <div>
          <div className="icon">
            <FontAwesomeIcon icon={faRoad} />
          </div>
          <p>
            <Trans>benefits.item-1</Trans>
          </p>
        </div>
      </div>

      <div className="item">
        <div>
          <div className="icon">
            <FontAwesomeIcon icon={faUserShield} />
          </div>
          <p>
            <Trans>benefits.item-2</Trans>
          </p>
        </div>
      </div>

      <div className="item">
        <div>
          <div className="icon">
            <FontAwesomeIcon icon={faDesktop} />
          </div>
          <p>
            <Trans>benefits.item-3</Trans>
          </p>
        </div>
      </div>

      <div className="item">
        <div>
          <div className="icon">
            <FontAwesomeIcon icon={faCogs} />
          </div>
          <p>
            <Trans>benefits.item-4</Trans>
          </p>
        </div>
      </div>

      <div className="item">
        <div>
          <div className="icon">
            <FontAwesomeIcon icon={faExpand} />
          </div>
          <p>
            <Trans>benefits.item-5</Trans>
          </p>
        </div>
      </div>

      <div className="item">
        <div>
          <div className="icon">
            <FontAwesomeIcon icon={faChartBar} />
          </div>
          <p>
            <Trans>benefits.item-6</Trans>
          </p>
        </div>
      </div>

      <div className="item">
        <div>
          <div className="icon">
            <FontAwesomeIcon icon={faShapes} />
          </div>
          <p>
            <Trans>benefits.item-7</Trans>
          </p>
        </div>
      </div>

      <div className="item">
        <div>
          <div className="icon">
            <FontAwesomeIcon icon={faSlidersH} />
          </div>
          <p>
            <Trans>benefits.item-8</Trans>
          </p>
        </div>
      </div>

      <div className="item">
        <div>
          <div className="icon">
            <FontAwesomeIcon icon={faLeafHeart} />
          </div>
          <p>
            <Trans>benefits.item-9</Trans>
          </p>
        </div>
      </div>

      <div className="item">
        <div>
          <div className="icon">
            <FontAwesomeIcon icon={faPiggyBank} />
          </div>
          <p>
            <Trans>benefits.item-10</Trans>
          </p>
        </div>
      </div>

      <div className="item">
        <div>
          <div className="icon">
            <FontAwesomeIcon icon={faTrafficLightGo} />
          </div>
          <p>
            <Trans>benefits.item-11</Trans>
          </p>
        </div>
      </div>
    </Slider>
  )
}
export default BenefitsSlider
