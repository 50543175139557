import React from "react"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"

import systemPreview from "images/home/system-preview-devices.png"
import marketingTeam from "images/home/trmz-marketing-team.jpg"
import solution1 from "images/home/solutions/solution-1.png"
import solution2 from "images/home/solutions/solution-2.png"
import solution3 from "images/home/solutions/solution-3.png"
import solution4 from "images/home/solutions/solution-4.png"
import solution5 from "images/home/solutions/solution-5.png"
import solution6 from "images/home/solutions/solution-6.png"
import solution7 from "images/home/solutions/solution-7.png"
import googleplay from "../images/google-play-button.svg"
import appstore from "../images/app-store-button.svg"

import { faCheck, faHeartbeat } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"
import BenefitsSlider from "../components/benefits_slider"
import Recommendation from "../components/recommendation"

const IndexPage = () => {
  const { language } = useI18next()

  const { t } = useTranslation()

  return (
    <Layout variant="home">
      <SEO title="Home" />

      <article className="home" id="start-now">
        <header className="home__header">
          <div className="left-side">
            <h1>
              <Trans i18nKey="hero.heading-1" components={{ span: <span /> }} />
            </h1>
            <div className="booking-cta">
              <h2>
                <Trans>hero.paragraph</Trans>
              </h2>
              <a
                href="https://tiramizoo.com/contact/"
                rel="noopener noreferrer"
                className="btn btn--primary btn--md"
                target="_blank"
              >
                <Trans>cta-booking.button</Trans>
              </a>
              <div className="suplement">
                <Trans>cta-booking.paragraph</Trans>
              </div>
            </div>
          </div>

          <div className="right-side">
            <img src={systemPreview} alt="Last Mile Master system" />
            <a href="#browse" className="btn btn--gray-light btn--md">
              <Trans>cta-browse.button</Trans>
            </a>
            <p>
              <Trans
                i18nKey="cta-not-solution.paragraph"
                components={{ span: <span /> }}
              />
              <a
                href={`https://tiramizoo.com/${
                  language == "de" ? "" : "en"
                }/tms`}
                rel="noopener noreferrer"
              >
                <Trans>cta-not-solution.button</Trans>
              </a>
            </p>
          </div>
        </header>

        <section className="home__status">
          <FontAwesomeIcon icon={faHeartbeat} />
          <p>
            <Trans>status.paragraph</Trans>:
          </p>
          <a
            href="https://status.tiramizoo.com/"
            rel="noopener noreferrer"
            className="btn btn--primary btn--sm"
            target="_blank"
          >
            <Trans>status.button</Trans>
          </a>
        </section>

        <section className="home__benefits slick-carousel-benefits">
          <header className="article-heading">
            <h2 className="heading heading--md">
              <Trans>benefits.heading</Trans>
            </h2>
          </header>
          <BenefitsSlider />
        </section>

        <section className="home__booking">
          <div className="home__booking--wrapper">
            <div className="home__booking--left">
              <img src={marketingTeam} alt="trmz marketing team" />
            </div>
            <div className="home__booking--right">
              <ol>
                <li>
                  <div className="home__booking-week">
                    <Trans>booking.week</Trans>
                  </div>
                  <h2>
                    <Trans>booking.heading-1</Trans>
                  </h2>
                  <p>
                    <Trans
                      i18nKey="booking.paragraph-1"
                      components={{ span: <span /> }}
                    />
                  </p>
                  <ul>
                    <li>
                      <div className="home__step-bullet">
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                      <Trans>booking.item-1-1</Trans>
                    </li>
                    <li>
                      <div className="home__step-bullet">
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                      <Trans>booking.item-1-2</Trans>
                    </li>
                    <li>
                      <div className="home__step-bullet">
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                      <Trans>booking.item-1-3</Trans>
                    </li>
                  </ul>
                  <a
                    href="https://tiramizoo.com/contact/"
                    rel="noopener noreferrer"
                    className="btn btn--primary btn--sm"
                    target="_blank"
                  >
                    <Trans>cta-booking.button</Trans>
                  </a>
                </li>
                <li>
                  <div className="home__booking-week">
                    <Trans>booking.week</Trans>
                  </div>
                  <h2>
                    <Trans>booking.heading-2</Trans>
                  </h2>
                  <p>
                    <Trans>booking.paragraph-2</Trans>
                  </p>
                </li>
                <li>
                  <div className="home__booking-week">
                    <Trans>booking.week</Trans>
                  </div>
                  <h2>
                    <Trans>booking.heading-3</Trans>
                  </h2>
                  <p>
                    <Trans>booking.paragraph-3</Trans>
                  </p>
                </li>
                <li>
                  <div className="home__booking-week">
                    <Trans>booking.week</Trans>
                  </div>
                  <h2>
                    <Trans>booking.heading-4</Trans>
                  </h2>
                  <p>
                    <Trans>booking.paragraph-4</Trans>
                  </p>
                </li>
                <li className="final-step">
                  <h2 className="heading heading--sm">
                    <Trans>booking.heading-5</Trans>
                  </h2>
                  <p>
                    <Trans>booking.paragraph-5</Trans>
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </section>

        <section class="recommendations-section slick-carousel">
          <div class="section-wrapper">
            <Recommendation />
          </div>
        </section>

        <section className="home__booking-cta">
          <header className="article-heading">
            <h2 className="heading heading--md">
              <Trans
                i18nKey="cta-box.heading"
                components={{ span: <span /> }}
              />
            </h2>
            <h3 className="heading heading--sm">
              <Trans>cta-box.paragraph</Trans>
            </h3>
          </header>

          <a
            href="https://tiramizoo.com/contact/"
            className="btn btn--primary btn--md"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Trans>cta-booking.button</Trans>
          </a>
          <p>
            <Trans>cta-booking.paragraph</Trans>
          </p>
        </section>

        <section className="steps__section solutions-section" id="browse">
          <header className="article-heading">
            <h2 className="heading heading--md">
              <Trans>solutions.heading</Trans>
            </h2>
            <p className="paragraph paragraph--lg">
              <Trans>solutions.paragraph</Trans>
            </p>
          </header>
          <ol className="home__steps">
            <li className="home__step">
              <div className="home__img">
                <figure>
                  <img src={solution1} alt="trmz marketing team" />
                </figure>
              </div>
              <div className="home__txt">
                <h2>
                  <Trans>solutions.solution-heading-1</Trans>
                </h2>
                <p>
                  <Trans
                    i18nKey="solutions.solution-paragraph-1"
                    components={{ span: <span /> }}
                  />
                </p>
              </div>
            </li>
            <li className="home__step">
              <div className="home__img">
                <figure>
                  <img src={solution2} alt="trmz marketing team" />
                </figure>
              </div>
              <div className="home__txt">
                <h2>
                  <Trans>solutions.solution-heading-2</Trans>
                </h2>
                <p>
                  <Trans
                    i18nKey="solutions.solution-paragraph-2"
                    components={{ span: <span /> }}
                  />
                </p>
              </div>
            </li>
            <li className="home__step">
              <div className="home__img">
                <figure>
                  <img src={solution3} alt="trmz marketing team" />
                </figure>
              </div>
              <div className="home__txt">
                <h2>
                  <Trans>solutions.solution-heading-3</Trans>
                </h2>
                <p>
                  <Trans
                    i18nKey="solutions.solution-paragraph-3"
                    components={{ span: <span /> }}
                  />
                </p>
              </div>
            </li>
            <li className="home__step">
              <div className="home__img">
                <figure>
                  <img src={solution4} alt="trmz marketing team" />
                </figure>
              </div>
              <div className="home__txt">
                <h2>
                  <Trans>solutions.solution-heading-4</Trans>
                </h2>
                <p>
                  <Trans
                    i18nKey="solutions.solution-paragraph-4"
                    components={{ span: <span /> }}
                  />
                </p>
              </div>
            </li>
            <li className="home__step">
              <div className="home__img">
                <figure>
                  <img src={solution5} alt="trmz marketing team" />
                </figure>
              </div>
              <div className="home__txt">
                <h2>
                  <Trans>solutions.solution-heading-5</Trans>
                </h2>
                <p>
                  <Trans
                    i18nKey="solutions.solution-paragraph-5"
                    components={{ span: <span /> }}
                  />
                </p>
                <div class="buttons">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.tiramizoo.android"
                  >
                    <img src={googleplay} alt="app preview" />
                  </a>
                  <a
                    target="_blank"
                    href="https://apps.apple.com/pl/app/tiramizoo-kurier/id593895478?l"
                  >
                    <img src={appstore} alt="app preview" />
                  </a>
                </div>
              </div>
            </li>
            <li className="home__step">
              <div className="home__img">
                <figure>
                  <img src={solution6} alt="trmz marketing team" />
                </figure>
              </div>
              <div className="home__txt">
                <h2>
                  <Trans>solutions.solution-heading-6</Trans>
                </h2>
                <p>
                  <Trans
                    i18nKey="solutions.solution-paragraph-6"
                    components={{ span: <span /> }}
                  />
                </p>
              </div>
            </li>
            <li className="home__step">
              <div className="home__img">
                <figure>
                  <img src={solution7} alt="trmz marketing team" />
                </figure>
              </div>
              <div className="home__txt">
                <h2>
                  <Trans>solutions.solution-heading-7</Trans>
                </h2>
                <p>
                  <Trans
                    i18nKey="solutions.solution-paragraph-7"
                    components={{ span: <span /> }}
                  />
                </p>
              </div>
            </li>
          </ol>
        </section>

        <section className="home__booking-cta">
          <header className="article-heading">
            <h2 className="heading heading--md">
              <Trans
                i18nKey="cta-box.heading"
                components={{ span: <span /> }}
              />
            </h2>
            <h3 className="heading heading--sm">
              <Trans>cta-box.paragraph</Trans>
            </h3>
          </header>

          <a
            href="https://tiramizoo.com/contact/"
            className="btn btn--primary btn--md"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Trans>cta-booking.button</Trans>
          </a>
          <p>
            <Trans>cta-booking.paragraph</Trans>
          </p>
        </section>
      </article>
    </Layout>
  )
}

export default IndexPage
